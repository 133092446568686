<template lang="pug">
    #imageLink
        ul
            li
                a(:href="contents.content.link")
                    .img
                        img(:src="image").image
</template>

<script>
import { props } from '@/mixins/component'

export default {
    name: "component-image-link",
    props,
    computed: {
        image() {
            return `${process.env.VUE_APP_FILE_API_ROUTE}/medias/media/${this.contents.content.image}`
        }
    }
    
}
</script>

<style lang="stylus" scoped src="./ImageLink.styl"></style>